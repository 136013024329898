nav{
    display: flex;
    height: auto;
    width: 100%;
    background: #1b1b1b;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px 0 100px;
    flex-wrap: wrap;
  }
  nav .logo{
    color: #fff;
    font-size: 35px;
    font-weight: 600;
  }
  nav ul{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }
  nav ul li{
    margin: 0 5px;
  }
  nav ul li a{
    color: #f2f2f2;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    padding: 8px 15px;
    border-radius: 5px;
    letter-spacing: 1px;
    transition: all 0.3s ease;
  }
  nav ul li a.active,
  nav ul li a:hover{
    color: #111;
    /* text-decoration: underline; */
    /* background: #fff; */
  }
  nav .menu-btn {
    display: none;
  }
  nav .menu-btn i{
    color: #fff;
    font-size: 22px;
    cursor: pointer;
    display: none;
  }
  input[type="checkbox"]{
    display: none;
  }
nav ul {
    width: 100%;
    display: flex;
    justify-content: end;
}
  @media (max-width: 1000px){
    nav{
      padding: 0 40px 0 50px;
    }
  }
  @media (max-width: 920px) {
    nav .menu-btn i{
      display: block;
    }
    #click:checked ~ .menu-btn i:before{
      content: "\f00d";
    }
    nav ul{
      position: fixed;
      top: 80px;
      left: -100%;
      background: #111;
      height: 100vh;
      width: 100%;
      text-align: center;
      display: block;
      transition: all 0.3s ease;
    }
    #click:checked ~ ul{
      left: 0;
    }
    nav ul li{
      width: 100%;
      margin: 40px 0;
    }
    nav ul li a{
      width: 100%;
      margin-left: -100%;
      display: block;
      font-size: 20px;
      transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
    #click:checked ~ ul li a{
      margin-left: 0px;
    }
    nav ul li a.active,
    nav ul li a:hover{
      background: none;
      color: cyan;
    }
  }
  .content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: -1;
    width: 100%;
    padding: 0 30px;
    color: #1b1b1b;
  }
  .content div{
    font-size: 40px;
    font-weight: 700;
  }

  /* end  */
.colorChange {
    height: 60px !important;
    background: rgb(57 57 57);
    color: #fff;
}

.colorChange .navbar-light .navbar-nav .nav-link {
    color: #fff !important;
}


.landing-user{
    font-size: 12px;
    color: rgba(255, 255, 255, 0.65);
    font-family: Poppins-Regular;
    margin: 2px 0px ;
    cursor: pointer;
}
.landing-user:hover{
   text-decoration: underline;
}
.landing-profile{
        height: 35px;
        width: 35px;
        /* background-color: antiquewhite; */
        border-radius: 50%;
        overflow: hidden;
        margin: 0px 6px 0px 20px;
}

@media (max-width: 768px) {
  nav .menu-btn {
    display: block;
  }
  nav ul {
    /* top: 73px; */
    top: 64px;
  }
  #click:checked ~ ul li a {
    color: #fff;
}
    .colorChange {
        height: auto !important;
       
    }
    .navbar-light .navbar-toggler {
        float: right;
    }
    .collapse.show {
        display: block;
        float: left !important;
        width: 98% !important;
        margin-right: 430px !important;
    }
  
    .navbar ul {
        margin: 0;
        height: 80%;
        padding-left: 80px;
        display: flex;
        justify-content: center;
        list-style: none;
        align-items: center;
        padding-bottom: 190px;
    }
    nav ul li {
        width: 100%;
        margin: 0px 0;
    }
    .logo .collapse.show {
        position: absolute;
    top: 0px;
    }
    .user-responsive{
        display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    }
    
}

@media (max-width: 425px) {
    .navbar ul {
        padding-left: 25px;
    }
  
}

@media (max-width: 375px) {
  nav ul {
    top: 69px;
  }
}


