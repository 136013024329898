.nav-Custom-Container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.distance-width {
  /* width: 58vw; */
}
.navBar-CustomToggle{
    display: none;
  }
  a {
      transition: background 0.2s, color 0.2s;
    }
    a:hover,
    a:focus {
      text-decoration: none;
    }
    
    #wrapper {
      padding-left: 0;
      transition: all 0.5s ease;
      position: relative;
    }
    
    #sidebar-wrapper {
      z-index: 1000;
      /* position: fixed; */
      position: absolute;
      left: 250px;
      width: 0;
      height: 100%;
      margin-left: -250px;
      overflow-y: auto;
      overflow-x: hidden;
      background: #FFFFFF;
      /* border: 1px solid #38436424; */
      border-right: 1px solid #38436424;
      transition: all 0.5s ease;
    }
    #sidebar-wrapper .profileBox{
      /* display: flex;
      justify-content: center;
      align-items: center; */
    }
    
    #sidebar-wrapper .profile-Container{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
    }
    #sidebar-wrapper .profile-img{
      width: 65px;
      height: 65px;
      background: #919191;
      border-radius: 50%;
      overflow: hidden;
    }
    #sidebar-wrapper .user-name{
      color: #384364;
      font-size: 20px;
      font-family: Poppins-Bold;
      text-align: center;
      margin-top: 8px;
    }
    #sidebar-wrapper .user-email{
      color: #384364;
      font-size: 14px;
      font-family: Poppins-Regular;
      text-align: center;
      margin-top: -8px;
    }
    
    #wrapper.toggled #sidebar-wrapper {
      width: 250px;
    }
    
    .sidebar-brand {
      position: absolute;
      top: 0;
      width: 250px;
      text-align: center;
      padding: 20px 0;
    }
    .sidebar-brand h2 {
      margin: 0;
      font-weight: 600;
      font-size: 24px;
      color: #fff;
    }
    
    .sidebar-nav {
      position: absolute;
      top: 70px;
      width: 250px;
      margin: 0;
      padding: 0;
      list-style: none;
    }
    .sidebar-nav > li {
      text-indent: 10px;
      line-height: 42px;
      margin-bottom: 14px;
    }
    .sidebar-nav > li a {
      display: block;
      text-decoration: none;
      color: #0282A5;
      font-weight: 600;
      font-size: 12px;
      margin: 0px 14px;
    }
    .sidebar-nav > li > a:hover,
    .sidebar-nav > li.active > a {
      text-decoration: none;
      color: #FFFFFF;
      background: #FF9517;
      margin: 0px 14px;
      border-radius: 12px;
    }
    .sidebar-nav > li > a i.fa {
      font-size: 24px;
      width: 60px;
    }
    
    #navbar-wrapper {
      width: 100%;
      position: absolute;
      z-index: 2;
      border-bottom: 1px solid #70707029;
  }
    #wrapper.toggled #navbar-wrapper {
        position: absolute;
        margin-right: -250px;
    }
    #navbar-wrapper .navbar {
      border-width: 0 0 0 0;
      background-color: #FFFFFF;
      font-size: 24px;
      margin-bottom: 0;
      border-radius: 0;
      /* height: 68px; */
    }
    #navbar-wrapper .navbar a {
      color: #0282A5;
    }
    #navbar-wrapper .navbar a:hover {
      color: #FF9517;
    }
  
  #navbar-wrapper .navbar a:active {
      color: #FF9517 !important;
  }
    
    #navbar-wrapper .dashboard-Logo img {
      height: 22px;
      margin-left: 62px;
    }
    
    #content-wrapper {
      width: 100%;
      height: 94vh;
      position: absolute;
      /* padding: 15px; */
      padding-left: 250px;
      padding-right: 120px;
      top: 100px;
      background-color:#F6F6F8 ;
    }
    #wrapper.toggled #content-wrapper {
      position: absolute;
      margin-right: -250px;
    }
  
  
    .sidebar-nav > li .card .card-header > button {
      display: block;
      text-decoration: none;
      color: #A2A2A2;
      font-weight: 600;
      font-size: 18px;
      margin: 0px 14px;
  }
  .sidebar-nav > li .card .card-header > button:hover {
    text-decoration: none;
    color: #5C3DA4;
    background: #E4E1ED;
    margin: 0px 1px;
    border-radius: 12px;
  }
  .sidebar-nav > li .card .card-header > button:hover .accordian-icon {
    margin-left: 3px;
  }
  
  .sidebar-nav > li .card .card-header > button:focus {
    box-shadow: none;
  }
  
  
  .sidebar-nav .accordian-icon{
    /* margin-left: 3px; */
    margin-left: -10px;
    font-size: 24px;
    width: 60px;
  }
  .sidebar-nav .accordian-items{
    font-size: 14px;
    color: #121212;
    font-family: Poppins-Medium;
    height: 22px;
  }
  
  /* .sidebar-nav > li:active > .card .card-header {
    text-decoration: none;
    color: #5C3DA4;
    background: #E4E1ED;
    margin: 0px 14px;
    border-radius: 12px;
  } */
  
  .dropdown-item.active, .dropdown-item:active {
    color: #000 !important;
    text-decoration: underline !important;
    background-color: transparent !important;
  }
  .dropdown-item.active, .dropdown-item:hover {
    color: #000 !important;
    text-decoration: underline !important;
    background-color: transparent !important;
  }
  .dropdown-item:focus, .dropdown-item:focus {
    background-color: transparent !important;
  }
  .dropdown-item:focus, .dropdown-item:hover {
    background-color: transparent !important;
  }
  
  .accordion .card{
    border: none !important;
  }
  .accordion .card .card-header{
    border-bottom: none !important; 
  }
  

  
    @media (min-width: 1024px) {
      #sidebar-wrapper{
        overflow-y: scroll;
        /* height: 91%; */
        max-height: 92vh;
      }
      #content-wrapper {
        height: 92vh;
        overflow-y: scroll;
      }
      .distance-width {
        /* width: 58vw; */
      }
      
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      .distance-width {
        /* width: 300px; */
      }
      #content-wrapper {
        padding-right: 90px;
      }
    }
    @media (max-width: 992px) {
      .distance-width {
        /* width: 250px; */
      }
    }
    /* @media (min-width: 769px) and (max-width: 870px) {
      .distance-width {
        width: 250px;
      }
    } */
  
    @media (min-width: 992px) {
      #wrapper {
        /* padding-left: 250px; */
      }
      
      #wrapper.toggled {
        padding-left: 60px;
      }
    
      #sidebar-wrapper {
        width: 250px;
      }
      
      #wrapper.toggled #sidebar-wrapper {
        width: 76px;
      }
      
      #wrapper.toggled #navbar-wrapper {
        position: absolute;
        margin-right: -190px;
    }
      
      #wrapper.toggled #content-wrapper {
        position: absolute;
        margin-right: -190px;
      }
    
      #navbar-wrapper {
        position: relative;
      }
    
      #wrapper.toggled {
        padding-left: 0px;
      }
    
      #content-wrapper {
        position: relative;
        top: 0;
        /* padding-left: 269px;
        padding-right: 269px */
      }
    
      #wrapper.toggled #navbar-wrapper,
      #wrapper.toggled #content-wrapper {
        position: relative;
        margin-right: 60px;
      }
     
    }
    
    @media (min-width: 768px) and (max-width: 991px) {
      .navBar-CustomToggle{
        display: block;
      }
      #wrapper {
        /* padding-left: 60px; */
        padding-left: 0px;
      }
  
      #sidebar-wrapper {
        /* width: 60px; */
        width: 0px;
        height: 111vh;
      }
      #wrapper.toggled #sidebar-wrapper {
        width: 250px;
        height: 118vh;
    }
      
    #wrapper.toggled #navbar-wrapper {
        position: absolute;
        margin-right: -250px;
    }
      
      #wrapper.toggled #content-wrapper {
        position: absolute;
        margin-right: -250px;
      }
    
      #navbar-wrapper {
        /* position: relative; */
        position: fixed;
      }
    
      #wrapper.toggled {
        /* padding-left: 250px; */
        padding-left: 0px;
      }
    
      #content-wrapper {
        position: relative;
        top: 0;
        height: 100%;
        /* height: 118vh; */
      }
    
      #wrapper.toggled #navbar-wrapper,
      #wrapper.toggled #content-wrapper {
        position: relative;
        margin-right: 250px;
            /* height: 112vh; */
      }
    }

    @media (max-width: 768px) {
      .distance-width {
        /* width: 262px; */
      }
      #content-wrapper {
        /* top: 50px; */
        padding-left: 70px;
        padding-right: 70px;
      }
      .navbar-light .navbar-toggler-icon {
        background-color: #cfcfcfa8;
      }
    }
    
    @media (max-width: 767px) {
      .navBar-CustomToggle{
        display: block;
      }
      #wrapper {
        padding-left: 0;
      }
    
      #sidebar-wrapper {
        width: 0;
      }
    
      #wrapper.toggled #sidebar-wrapper {
        width: 250px;
      }
      #wrapper.toggled #navbar-wrapper {
        position: absolute;
        margin-right: -250px;
      }
    
      #wrapper.toggled #content-wrapper {
        position: absolute;
        margin-right: -250px;
      }
    
      #navbar-wrapper {
        position: fixed;
      }
    
      #wrapper.toggled {
        padding-left: 0px;
      }
    
      #content-wrapper {
        position: relative;
        top: 0;
        height: 100%;
        overflow-y: scroll;
      }
    
      #wrapper.toggled #navbar-wrapper,
      #wrapper.toggled #content-wrapper {
        position: relative;
        margin-right: 250px;
      }
    }
  
    @media (max-width: 425px) {
      .nav-Custom-Container {
        padding: 0px 10px;
    }
      #navbar-wrapper .dashboard-Logo img {
        margin-left: 0px;
      }
      .navbar-title {
        font-size: 16px;
      }
      #content-wrapper {
        padding-left: 25px;
        padding-right: 25px;
    }
    .mobile-view{
      padding: 4px 22px;
    }
    }
  
    @media (max-width: 375px) {
      .navbar-title {
        font-size: 12px;
      }
      .Home-main-Container .job-title-provider {
        font-size: 26px;
      }
    }
  
    @media (max-width: 320px) {
      #navbar-wrapper .dashboard-Logo img {
        height: 14px;
        margin-bottom: 9px;
      }
      .navbar-title {
        font-size: 10px;
        margin-bottom: 0px;
      }
      #content-wrapper {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  
  
  