.distance-width {
    /* max-width: 100%;
    width: 1320px; */
}
body{
    overflow-x: hidden;
}
.Active{
    color: #FF9517;
}
.comment-quoteP{
    font-size: 10px;
    text-align: initial;
    margin: 10px;
}
.sideBar-icon{
    margin-right: 40px;
}
.custom-topBar{
    /* margin-left: 855px; */
    /* width: 450px; */
}
.notification-icon{
    color: #0282A5;
}
.notification-icon:hover{
    color: #FF9517;
}
.dashboard-title {
    color: #0282A5;
    font-family: Poppins-SemiBold;
    font-size: 40px;
    text-align: center;
    margin-bottom: 45px;
}

.service-Container{
    padding: 20px 10px;
}

.service-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    height: 168px;
    padding: 20px 55px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}

.service-Container .serviceCard:hover {
    box-shadow: 4px 5px 5px 5px #6c6b6b52;
}

.service-Container .serviceTitle {
    font-family: Poppins-Medium;
    font-size: 15px;
    color: #000000;
    font-weight: 600;
    margin-top: 14px;
}

.service-Container .serviceDescription {
    font-size: 10px;
    font-family: Poppins-Regular;
    color: #000000;
    text-align: center;
    
    font-size: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0px;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
}

.service-Container .serviceCard-img{
    height: 40px;
    /* width: 40px; */
    text-align: center;
}

.service-Container .serviceCard-img img{
    width: 48px;
}
.icon-container {
    margin-top: -10px;
}

.service-Container .service-icon{
    color: #000000;
    font-size: 12px;
}

.btn.btn-primary {
    background: #FF9517 !important;
    /* background: linear-gradient(
    -45deg, #1391a5, #274685); */
    color: #fff;
    font-size: 12px;
    border-radius: 50px;
    box-shadow: 0 10px 30px 0 rgb(0 0 0 / 15%);
    width: 200px;
    padding: 12px 30px !important;
}

.btn-container{
    margin-top: 40px;
    margin-bottom: 10px;
    text-align: center;
}

/* === placeOrder === */
.placeOrder-Container{
    padding: 20px 10px;
}

.placeOrder-Container .formCard {
    background-color: #fff;
    width: 100%;
    /* height: 182px; */
    padding: 20px 82px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}

.placeOrder-Container .formCard input{
    border-radius: 30px;
    border: 1px solid #00000036;
    padding: 10px 38px;
    height: 38px;
} 
.placeOrder-Container ::placeholder{
   font-size: 13px;
} 

.placeOrder-Container .formCard select{
    border-radius: 30px;
    border: 1px solid #00000036;
    padding: 10px 38px;
    height: 38px ;
} 

.placeOrder-Container select.form-control:not([size]):not([multiple]) {
    height: 42px !important;
    font-size: 13px !important;
}

.placeOrder-Container .subTitle {
    font-size: 16px;
    color: #000000;
    font-family: Poppins-SemiBold;
    margin-bottom: 30px;
    margin-top: 14px;
}

.form-InnerTitle {
    color: #000000;
    font-size: 14px;
    font-family: Poppins-SemiBold;
}

.placeOrder-Container .inputForm-padding{
    padding: 35px 65px;
}

select.form-control:not([size]):not([multiple]) {
    /* height: calc(2.25rem + 2px); */
    height: 40px !important;
}

/* === orderTracking === */
.orderTracking-Container {
    padding: 20px 10px;
}
.orderTracking-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    padding: 44px 82px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}
.orderTracking-Container .tracking-points {
    color: #000000;
    font-size: 15px;
    font-family: Poppins-Regular;
    float: left;
}
.orderTracking-Container .tracking-points a{
    color: #107A9F;
    font-size: 15px;
    font-family: Poppins-Regular;
    text-decoration: underline;
    margin-left: 10px;
}
.orderTracking-Container .tracking-points a:hover{
    text-decoration: underline !important;
}
.orderTracking-Container .orderTrack-icon{
    color: #FF9517;
    font-size: 26px;
    float: right;
}
.orderTracking-Container .ordertrack-input input{
    text-align: center;
}

 /* === Report issue === */
.Report-Container{
    padding: 20px 10px;
}
.Report-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    padding: 44px 82px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}
.Report-Container .report-msg textarea{
    margin-top: 30px;
    height: 200px;
}

/* === RiderTracking === */
.RiderTracking-Container{
    padding: 20px 10px;
}
.RiderTracking-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    height: 450px;
    /* padding: 44px 82px; */
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}

/* == Employment Opportunity == */
.EmploymentOpt-Container {
    /* padding: 20px 10px; */
    padding: 38px 64px;
}
.EmploymentOpt-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    height: auto;
    padding: 44px 44px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}
.EmploymentOpt-Container .search-input {
    border-radius: 50px 0px 0px 50px;
    padding: 9px 30px !important;
    width: 60%;
    outline: 0;
    border: 1px solid #7676766b;
    font-size: 13px;
    margin-right: 40px;
}

.EmploymentOpt-Container .search-btn {
    background: #FF9517;
    color: #fff;
    text-align: center;
    padding: 6px 30px !important;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    line-height: 1.5;
    border-radius: 0px 50px 50px 0px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-left: -30px;
}

.EmploymentOpt-Container .jobCard-Dropdown{
    padding: 9px 30px !important;
    outline: 0;
    border: 1px solid #7676766b;
    font-size: 13px;
    border-radius: 50px;
}
.EmploymentOpt-Container select.form-control:not([size]):not([multiple]) {
    height: unset !important;
}
.EmploymentOpt-Container .searchCount-result {
    font-size: 12px;
    color: #636060e8;
    font-family: Poppins-Regular;
}

.EmploymentOpt-Container .search-Job-container{
    border-top: 2px solid #70707040;
    padding: 28px 28px;
}  

.EmploymentOpt-Container .job-title {
    font-size: 18px;
    font-family: Poppins-Medium;
    color: #000000;
    text-align: initial;
    margin-bottom: 18px;
    margin-top: 20px;
}
.EmploymentOpt-Container .job-desc {
    font-size: 14px;
    font-family: Poppins-Regular;
    color: #000000;
    text-align: initial;
}

.EmploymentOpt-Container .job-address {
    font-size: 12px;
    font-family: Poppins-Regular;
    color: #636060d9;
    text-align: end;
    margin: 0px;
}
.EmploymentOpt-Container .searchjob-icon{
    text-align: end;
    margin: 12px 0px;
}

.EmploymentOpt-Container .Searchjob-btn{
    padding: 7px 25px !important;
    font-size: 12px;
}

.EmploymentOpt-Container .feature-tag{
    background-color: #fd8b5621;
    color: #FF844B;
    padding: 10px;
    width: 100px;
    font-size: 12px;
}

.EmploymentOpt-Container .searchjob-scroll{
    height: 455px;
    overflow-y: scroll;
}


/* == selected Employment Opportunity == */
.Employment-Container {
    /* padding: 20px 10px; */
    padding: 38px 64px;
}
.Employment-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    height: 505px;
    padding: 44px 82px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}

.Employment-Container .job-para{
    color: #000000;
    font-family: Poppins-Light;
    font-size: 14px;
    text-align: initial;
}

.Employment-Container .EmploymentTitle{
    font-size: 20px;
    font-family: Poppins-SemiBold;
    color: #000000;
}

.Employment-Container .jobDesc-title{
    font-size: 16px;
    font-family: Poppins-Medium;
    color: #000000;
    text-align: initial;
}

.Employment-Container .feature-tag{
    background-color: #fd8b5621;
    color: #FF844B;
    padding: 10px;
    width: 100px;
}
.Employment-Container .job-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    margin-top: 50px;
}
.Employment-Container .address{
    font-size: 12px;
    font-family: Poppins-Regular;
    margin: 0px;
}
.Employment-Container .starIcon{
    margin-right: 40px;
}

.flexBox-style{
    display: flex;
    justify-content: center;
    align-items: center;
}

 /* == Employment Form == */
 .EmploymentForm-Container {
    /* padding: 20px 10px; */
    padding: 38px 64px;
}
.EmploymentForm-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    /* height: 505px; */
    padding: 44px 82px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}
.EmploymentForm-Container .uploadContainer  {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
.EmploymentForm-Container .uploadContainer  input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}
.EmploymentForm-Container .subTitle{
    color: #000000;
    font-size: 14px;
    font-family: Poppins-Regular;
    margin-bottom: 10px;
}

/* == contractor  == */
.contractor-Container {
    /* padding: 20px 10px; */
    padding: 38px 64px;
}
.contractor-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    height: auto;
    padding: 44px 30px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}
.contractor-Container .contractor-desc{
    font-size: 16px;
    font-family: Poppins-Regular;
    color: #636060;
    text-align: initial;
}

.contractor-Container .jobCard-Dropdown{
    padding: 9px 40px !important;
    outline: 0;
    border: 1px solid #7676766b;
    font-size: 13px;
    border-radius: 50px;
    width: auto;
}
.contractor-Container .search-innerContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 28px;
}
.contractor-Container select.form-control:not([size]):not([multiple]) {
    height: unset !important;
}

.contractor-Container .card-Title{
    font-size: 20px;
    font-family: Poppins-SemiBold;
    color: #000000;
}

.contractor-Container  .contractor-Card {
    background-color: #fff;
    width: 100%;
    height: auto;
    padding: 20px 19px;
    border-radius: 12px;
    text-align: center;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}

.contractor-Container .cont-card-title{
    color: #000000;
    font-family: Poppins-SemiBold;
    font-size: 16px;
}
.contractor-Container .cont-card-detail{
    font-size: 10px;
    color: #000000;
    font-family: Poppins-Regular;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.contractor-Container .viewDetail{
    color: #0282A5;
    font-size: 14px;
    font-family: Poppins-Regular;
    text-decoration: underline;
    cursor: pointer;
}

.contractor-Container .search-btn {
    padding: 8px 30px !important;
}

/* === PaymentMethod === */
.PaymentMethod-Container {
    padding: 20px 10px;
}
.PaymentMethod-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    /* height: 505px; */
    padding: 44px 82px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}
.PaymentMethod-Container .subTitle{
    font-size: 20px;
    font-family: 'Poppins-SemiBold';
    color: #000000;
}
.PaymentMethod-Container .paymentCard {
    font-size: 10px;
    color: #000000;
    margin: 0px;
}
.PaymentMethod-Container .paymentCard-num {
    font-size: 20px;
    color: #343A40;
    margin: 0px;
}

.PaymentMethod-Container .check-Icon{
    color: #FF9517;
}
.PaymentMethod-Container .icon-delete{
   font-size: 20px;
}
.PaymentMethod-Container .icon-delete :hover{
    color:#FF9517;
}

/* == DriverRating == */
.DriverRating-Container {
    padding: 20px 10px;
}
.DriverRating-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    /* height: 505px; */
    padding: 44px 82px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}
.DriverRating-Container .profile{
    height: 120px;
    width: 120px;
    border-radius: 50%;
    border: 3px solid #FF9517;
    margin-bottom: 20px;
    overflow: hidden;
}

.DriverRating-Container .DriverName{
    color: #0282A5;
    font-size: 20px;
    font-family: Poppins-SemiBold;
    margin: 0px;
}

.DriverRating-Container .type {
    font-family: Poppins-Regular;
    font-size: 14px;
    margin-bottom: 16px;
}

.DriverRating-Container .status {
    font-family: Poppins-Regular;
    font-size: 11px;
    margin-top: 7px;
}

 

/* == order Info == */
.OrderInformation-Container {
    padding: 20px 10px;
}
.OrderInformation-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    /* height: 505px; */
    padding: 44px 82px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}
.OrderInformation-Container .subtitle{
    font-size: 24px;
    color: #000000;
    font-family: Poppins-SemiBold;
    margin: 0px;
}

.OrderInformation-Container .orderNum{
    color: #0282A5;
    font-size: 16px;
    font-family: Poppins-SemiBold;
}

.OrderInformation-Container .detail-title{
    font-size: 15px;
    font-family: Poppins-SemiBold;
    color: #000000;
    margin-left: 4px;
}

.OrderInformation-Container .address{
    font-size: 15px;
    font-family: Poppins-Regular;
    color: #000000;
    margin-left: 20px;
}
.OrderInformation-Container  .Address-Container{
    display: flex;
    justify-content: unset;
    align-items: center;
}
.OrderInformation-Container .pickupTitle{
    font-size: 15px;
    color: #000000;
    font-family: Poppins-SemiBold;
    text-align: initial;
}
.OrderInformation-Container .pickup{
    color: #000000;
    font-size: 13px;
    font-family: Poppins-Regular;
    text-align: initial;
    margin-left: 10px;
}

.OrderInformation-Container .Amount {
    color: #FF9517;
    font-size: 27px;
    font-family: Poppins-SemiBold;
    margin-top: 44px;
}
.OrderInformation-Container .serviceType {
    color: #000000;
    font-family: 'Poppins-SemiBold';
    font-size: 20px;
}

/* == get Quote == */
.getQuote-Container {
    padding: 20px 10px;
    /* padding: 38px 64px; */
}
.getQuote-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    padding: 44px 82px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}
.getQuote-Container input{
    border-radius: 30px;
    border: 1px solid #00000036;
    padding: 10px 38px;
    height: 38px;
}
.getQuote-Container textarea{
    border-radius: 30px;
    border: 1px solid #00000036;
    padding: 10px 38px;
    height: 200px;
}
.getQuote-Container ::placeholder{
    font-size: 13px;
 } 

.getQuote-Container select.form-control:not([size]):not([multiple]) {
    height: 38px !important;
    border-radius: 30px;
    padding: 10px 38px;
    font-size: 13px;
}
.flexBox-start{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


/* == nav == */
.nav_logo {
    /* margin-left: 100px; */
    margin-left: 50px;
}

.nav_logo img {
    width: 33px;
}

.profile{
    height: 30px;
    width: 30px;
    border-radius: 8px;
    overflow: hidden;
}

.profile-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
}

.navbar-profile-name {
    font-size: 13px;
    color: #4E7D96;
    font-family: Poppins-Bold;
    margin: 0px;
}
.navbar-profile-name:hover {
  text-decoration: underline;
}
.navbar-profile-role {
    font-size: 10px;
    color: #4E7D96;
    font-family: Poppins-Regular;
    margin: 0px;
}

.custom-topBar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
.custom-topBar li {
    display: inline;
    padding: 0px 16px;
    font-size: 16px;
  }

.flexBox-style{
    display: flex;
    justify-content: center;
    align-items: center;
}
.flexBox-space{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.image-width{
    width: 100%;
}

.employment-btnContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/* .profile-container {
    padding-right: 30px;
} */

.EmploymentTitle{
    color: #000000;
    font-family: Poppins-SemiBold;
    font-size: 16px;
}

.payment-gallery{
    display: flex;
    justify-content: center;
    align-items: center;
}

.distance-width {
    /* width: 48vw; */
}
@media (max-width: 1920px) {
    .distance-width {
        /* width: 1320px; */
    }
}
@media (max-width: 1440px) {
    .distance-width {
        /* width: 838px; */
    }
}

@media (max-width: 1150px) {
    .distance-width {
        /* width: 48vw; */
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .distance-width {
        /* width: 690px; */
    }
}


@media (min-width: 768px) and (max-width: 1024px) {
    .distance-width {
        /* width: 50vw; */
    }
    .dashboard-title {
      font-size: 32px;
    }
    /* .profile-container {
        margin-right: 0px !important;
      } */

    .placeOrder-Container .formCard {
        padding: 20px 30px;
    } 
    .PaymentMethod-Container .serviceCard {
        padding: 44px 40px;
    }
    .OrderInformation-Container .serviceCard {
        padding: 44px 40px;
    }
    .OrderInformation-Container .detail-title {
        font-size: 12px;
        margin: 4px;
    }
    .OrderInformation-Container .address {
        font-size: 13px;
        text-align: initial;
    }
    .OrderInformation-Container .pickupTitle {
        font-size: 14px;
    }
    .EmploymentOpt-Container .serviceCard {
        padding: 44px 40px;
    }
    .EmploymentOpt-Container .search-btn {
        padding: 6px 12px !important;
    }
    .EmploymentOpt-Container .jobCard-Dropdown {
        padding: 9px 18px !important;
    }
    .EmploymentOpt-Container .Searchjob-btn {
        padding: 7px 20px !important;
    }
    .EmploymentOpt-Container .job-desc {
        font-size: 13px;
    }
    .EmploymentOpt-Container .job-title {
        font-size: 17px;
        margin-bottom: 12px;
    }
    .contractor-Container .contractor-desc {
        font-size: 14px;
    }
    .contractor-Container .search-innerContainer {
        padding: 5px 12px;
    }
    .contractor-Container .jobCard-Dropdown {
        padding: 9px 24px !important;
    }
    .contractor-Container .contractor-Card {
        padding: 20px 14px;
    }
    .contractor-Container .cont-card-title {
        font-size: 13px;
    }
    .contractor-Container .viewDetail {
        font-size: 13px;
    }
  }

@media (max-width: 768px) {
    .distance-width {
      /* width: 68vw; */
    }
    .nav_logo {
        margin-left: 0px;
    }
    .service-Container .serviceCard {
        padding: 20px 32px;
    }
    .dashboard-title {
        margin-top: 58px;
    }
    .placeOrder-Container .inputForm-padding {
        padding: 35px 40px;
    }
    .orderTracking-Container .serviceCard {
        margin-bottom: 214px;
    }
    .EmploymentOpt-Container .search-btn {
        padding: 6px 6px !important;
    }
    .EmploymentOpt-Container .search-Job-container {
        padding: 28px 12px;
    }
    .EmploymentOpt-Container .job-desc {
        font-size: 12px;
    }
    .EmploymentOpt-Container .feature-tag {
        padding: 8px;
    }
    .employment-btnContainer {
        display: block;
    }
    .contractor-Container .search-innerContainer {
        padding: 5px 0px;
    }
    .contractor-Container .jobCard-Dropdown {
        padding: 9px 22px !important;
    }
    .contractor-Container .search-btn {
        padding: 8px 20px !important;
    }
    .Report-Container .serviceCard {
        margin-bottom: 160px;
    }
}
  
@media (max-width: 767px) {
    .EmploymentOpt-Container .search-btn {
        padding: 6px 30px !important;
    }
    .track-package-section{
        margin-bottom: 20px;
    }
    .contractor-Container .contractor-desc {
        font-size: 14px;
    }
    .dashboard-title {
        font-size: 34px;
    }
    .contractor-Container .contractor-Card {
       margin-bottom: 24px;
    }
}
@media (max-width: 425px) {
    .nav_logo {
        /* margin-left: 12px; */
    }
    .distance-width {
        /* width: 48vw; */
    }
    

    .profile-container {
        margin-right: 0px;
    }
    .placeOrder-Container .formCard {
        padding: 20px 12px;
    }
    .dashboard-title {
        font-size: 28px;
        margin-bottom: 32px;
    }
    .OrderInformation-Container .serviceCard {
        padding: 44px 28px;
    }
    .OrderInformation-Container .orderNum {
        color: #0282A5;
        font-size: 14px;
    }
    .OrderInformation-Container .subtitle {
        font-size: 20px;
    }
    .OrderInformation-Container .address {
        text-align: initial;
    }
    .orderTracking-Container .tracking-points {
        font-size: 13px;
        text-align: initial;
    }
    .orderTracking-Container .serviceCard {
        padding: 44px 12px;
    }
    .getQuote-Container .serviceCard {
        padding: 44px 28px;
    }
    .EmploymentOpt-Container .serviceCard {
        padding: 44px 28px;
    }
    .EmploymentOpt-Container .search-btn {
        padding: 6px 12px !important;
    }
    .EmploymentOpt-Container .searchCount-result {
        font-size: 10px;
    }
    .EmploymentOpt-Container .Searchjob-btn {
        padding: 7px 40px !important;
    }
    .EmploymentOpt-Container .job-title {
        font-size: 16px;
        margin-bottom: 12px;
        margin-top: 18px;
    }
    .contractor-Container .serviceCard {
        padding: 44px 28px;
    }
    .contractor-Container .contractor-desc {
        margin-top: 8px;
    }
    .contractor-Container .search-innerContainer {
        display: block;
    }
    .contractor-Container select.form-control:not([size]):not([multiple]) {
        width: 80%;
    }
    .contractor-Container .search-btn {
        padding: 8px 60px !important;
    }
    .contractor-Container .cont-card-detail {
        font-size: 12px;
    }
    .Report-Container .serviceCard {
        padding: 44px 28px;
        margin-bottom: 18px;
    }
    .RiderTracking-Container .serviceCard {
        padding: 44px 28px;
    }
    .orderTracking-Container .orderTrack-icon {
        font-size: 22px;
    }
    .DriverRating-Container .serviceCard {
        padding: 44px 28px;
    }
    .payment-gallery {
        display: block;
    }
}

@media (max-width: 375px) { 
    .distance-width {
        /* width: 40vw; */
    }
    .dashboard-title {
        font-size: 20px;
    }
    .service-Container .serviceDescription {
        font-size: 12px;
    }
    .placeOrder-Container .inputForm-padding {
        padding: 35px 24px;
    }
    .PaymentMethod-Container .serviceCard {
        padding: 44px 24px;
    }
    .OrderInformation-Container .serviceCard {
        padding: 44px 20px;
    }
    .orderTracking-Container .serviceCard {
        padding: 44px 8px;
    }
    .getQuote-Container .serviceCard {
        padding: 44px 20px;
    }
    .EmploymentOpt-Container .serviceCard {
        padding: 44px 20px;
    }
    .contractor-Container .serviceCard {
        padding: 44px 20px;
    }
    .Report-Container .serviceCard {
        padding: 44px 20px;
    }
    .RiderTracking-Container .serviceCard {
        padding: 44px 20px;
    }
    .nav_logo img {
        width: 32px;
    }
    .custom-topBar li {
        padding: 0px 12px;
    }
    #navbar-wrapper .navbar a {
        font-size: 10px;
    }
}

@media (max-width: 320px) {
    .dashboard-title {
        margin-bottom: 20px;
    }
    .placeOrder-Container .subTitle {
        font-size: 15px;
    }
    .PaymentMethod-Container .subTitle {
        font-size: 14px;
    }
    .OrderInformation-Container .subtitle {
        font-size: 16px;
    }
    .OrderInformation-Container .serviceType {
        font-size: 16px;
    }
    .EmploymentOpt-Container .search-btn {
        padding: 6px 8px !important;
    }
    .contractor-Container .contractor-desc {
        font-size: 12px;
    }
    .contractor-Container .cont-card-title {
        font-size: 14px;
    }
    .navbar-profile-role {
        font-size: 5px;
    }
    .navbar-profile-name {
        font-size: 10px;
    }
    .profile {
        height: 20px;
        width: 20px;
    }
    .nav_logo img {
        width: 30px;
    }
    .orderTracking-Container ::placeholder{
        font-size: 12px;
    }
    .orderTracking-Container .orderTrack-icon {
        float: initial;
    }
}
